/* PaymentValidation.css */

/* General Container Styles */
.payment-validation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .status-message {
    margin-top: 20px;
  }
  
  .status {
    padding: 15px;
    border-radius: 5px;
    text-align: center;
  }
  
  .status.success {
    background-color: #d4edda;
    color: #155724;
  }
  
  .status.error {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  @media (max-width: 600px) {
    .card {
      padding: 15px;
    }
  
    h1 {
      font-size: 20px;
    }
  }
  