 .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1000; 
}
.navbar {
    background-color: #0056b3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 20;
}

.right-item{
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.credit-{
    color: #f1c40f;
    margin-right:30px;
    font-size: 1.5rem;
    text-decoration:none;
}

 #logo{
    font-family: "Satisfy", cursive;
    color: #fff;
    font-size:30px;
    margin: 0 0 0 14px;
    /* margin-left:8px; */
    font-weight: 400;
    font-style: italic;
    cursor: pointer;
    text-decoration: none;
}
#logo:hover{
    color: #05f792;
    cursor: pointer;
}

.navbar-left {
    flex: 1;
}

.navbar-center {
    display: flex;
    justify-content: center;
    flex: 2;
}

.nav-link {
    color: #FFFFFF;
    margin: 0 0.9rem;
    text-decoration: none;
    font-size: 1.5rem;
}
.nav-link:hover {
    color: #ffdd40;
}

  .nav-link:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }
  
  .nav-link:hover:after {
    width: 100%;
    color:red;
    background: #ffdd40;
  }
  
  .nav-link.active {
    color: #ffdd40;
  }
  
.dropdown-menu {
    position: absolute;
    top: 40px;
    left: 0;
    background-color: white;
    color: black;
    list-style: none;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .dropdown-link {
    color: black;
    text-decoration: none;
    padding: 10px;
    display: block;
  }
  
  .dropdown-link:hover {
    background-color: #ddd;
    border-radius: 5px;
  }
  
.navbar-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right:15px;
}

.login, .signup {
    color: #FFFFFF;
    margin-left: 1rem;
    text-decoration: none;
    font-size: 1.3rem;
}
.signup{
  border:1px solid #fff ;
  padding: 0 3px;
}
.signup:hover{
  background: #f1c4;
}

.nav-icon {
    display: none;
}

@media (max-width: 768px) {
    .nav-icon {
        display: block;
        /* position: absolute; */
        margin-left:15px;
        top: 0;
        right: 0;
        transform: translate(-50%, 2%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #ffdd40;
      }
    .navbar-center {
        display: none;
      }
      .credit-{
        display:none;
      }
      .navbar-center.mobile-menu {
        display: flex;
      }
      .menu-toggle {
        display: block;
      }
    .navbar-center {
        display: none;
    }
    .login, .signup {
        font-size: 0.9rem;
    }
    .navbar {
        justify-content: space-between;
    }

    .navbar-left {
        flex: 1;
        justify-content: center;
    }

    .navbar-right {
        flex: 1;
        justify-content: flex-end;
        align-items: center;
    }

    .navbar-right .menu-icon {
        display: block;
        font-size: 24px;
        cursor: pointer;
        color: #fff;
        margin-left: 15px; 
    }
}


.navbar-center.mobile-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%; 
    right: 0;
    width: 30%; 
    background-color: #343a40;
    padding: 10px 0;
    z-index: 1000;
    border-top: 1px solid #343a40;
}

.navbar-center.mobile-menu .nav-link {
    margin: 10px 0;
    font-size:1rem;
    text-align: center;
}



.nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
  }

@media (max-width: 450px){
    .navbar-center {
        display: none;
      }
      .credit-{
        display:none;
      }
      .navbar-center.mobile-menu {
        display: flex;
      }
      .menu-toggle {
        display: block;
      }
    .navbar-center {
        display: none;
    }
    .login, .signup {
        font-size: 0.9rem;
    }
    .navbar {
        justify-content: space-between;
    }

    .navbar-left {
        flex: 1;
        justify-content: center;
    }

    .navbar-right {
        flex: 1;
        justify-content: flex-end;
        align-items: center;
    }

    .navbar-right .menu-icon {
        display: block;
        font-size: 24px;
        cursor: pointer;
        color: #fff;
        margin-left: 15px; 
    }
    .login{
        display:none;
    }
    .nav-icon {
        display: block;
        /* position: absolute; */
        margin-left:15px;
        top: 0;
        right: 0;
        transform: translate(-50%, 2%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #ffdd40;
      }
}