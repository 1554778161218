.faq-container {
  padding: 20px;
  text-align: left;
}

.faq-container h2 {
  text-align: center;
}

.faq-item {
  margin-bottom: 20px;
}

.services-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.services-container h1 {
  font-size: 2.5em;
  color: #007BFF;
  text-align: center;
  margin-bottom: 20px;
}

.service-section {
  margin-bottom: 40px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

.service-section h2 {
  font-size: 2em;
  color: #007BFF;
  margin-bottom: 10px;
}

.service-section h3 {
  font-size: 1.5em;
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
}

.service-section p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #555;
  margin-bottom: 10px;
}

.More-Details {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  text-decoration: none;
}

.More-Details:hover {
  background-color: #0056b3;
}

.join-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid #007bff; 
  color: #007bff;
  transition: background-color 0.3s, color 0.3s;
}

.join-button:hover {
  background-color: #007bff;
  color: white;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  position: relative;
  box-sizing: border-box;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 1.2em;
  cursor: pointer;
  background-color: #007bff; 
  color: white;
  border: none;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3; /* Change to your preferred color */
}

/* Responsive Design */
@media (max-width: 600px) {
  .faq-container {
    padding: 10px;
  }

  .modal-content {
    width: 95%;
    padding: 15px;
  }

  .form-group input,
  .form-group textarea {
    padding: 6px;
  }

  .submit-button {
    padding: 8px;
  }
}

.MeetUrDoc-container {
  font-family: Arial, sans-serif;
  color: #333;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.MeetUrDoc-header {
  text-align: center;
  margin-bottom: 20px;
}

.MeetUrDoc-header h1 {
  font-size: 2.5em;
  color: #007BFF;
}

.MeetUrDoc-header .subheading {
  font-size: 1.2em;
  color: #555;
}

.MeetUrDoc-content h2 {
  color: #007BFF;
  font-size: 1.8em;
  margin-top: 20px;
}

.MeetUrDoc-content ul {
  list-style-type: none;
  padding: 0;
}

.MeetUrDoc-content ul li {
  margin: 10px 0;
  font-size: 1.1em;
}
.MeetUrDoc-content h2{
  color: #007BFF;
  text-align:center;
}
.MeetUrDoc-content p {
  font-size: 1.1em;
  line-height: 1.6;
}
.offer-cards-container {
  display: flex; 
  flex-wrap: wrap; 
  justify-content: center; 
}