.carousel-button{
  padding: 15px 25px;
  border: unset;
  border-radius: 15px;
  color: #212121;
  z-index: 1;
  background: #e8e8e8;
  position: relative;
  font-weight: 1000;
  font-size: 22px;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  transition: all 250ms;
  overflow: hidden;
 }
 
 .carousel-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 15px;
  background-color: #212121;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  transition: all 250ms
 }
 
 .carousel-button:hover {
  color: #e8e8e8;
 }
 
.carousel-button:hover::before {
  width: 100%;
 }
.main-div {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  width: 100%;
  height: 100vh;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carousel-heading{
  position: relative;
}
.carousel-heading {
  font-size: 10vw; /* make our h1 tag larger */
  font-family: sans-serif; 
  background: linear-gradient(to right, rgba(255, 215, 255, 0.2) 0%, rgba(225, 255, 255, 0.5) 20%, rgba(255, 255, 255, 0.1) 61%), linear-gradient(rgb(92, 18, 217) 52%, rgb(224, 246, 255) 60%, rgba(13, 59, 214, 0.8) 61%); 
  background-clip: text; 
  -webkit-background-clip: text; 
  -webkit-text-fill-color: transparent; 
  animation: wave 2000ms ease alternate infinite;
  transition: all 0.4s ease;
}

@keyframes wave {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50vw 10px;
  }
}
.text-danger{
  font-weight:600;
}

.carousel-caption.custom-caption {
  /* position: absolute; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color:#212121; 
  font-size: 2rem;
  font-weight: 800;
}

.carousel-caption h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

.carousel-caption p {
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .carousel-item img {
    content: url('../assets/frontui.jpeg');
  }
  .fs-3{
    color:#22fa0a;
   }

  .carousel-caption h1 {
    font-size: 2rem;
  }

  .carousel-caption p {
    font-size: 1.2rem;
  }

  .carousel-button{
    font-size: 1rem;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .carousel-item img {
    content: url('../assets/frontui.jpeg');
  }
  .fs-3{
    color:#22fa0a;
   }
  .carousel-caption h1 {
    font-size: 1.5rem;
  }

  .carousel-caption p {
    font-size: 1rem;
  }

  .carousel-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

@media (max-width: 400px) {
  .carousel-button{
    font-size: 0.9rem;
  }
}