.about-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
}

.intro, .mission, .features, .team, .contact {
    margin-bottom: 40px;
}

.about-container h1, h2 {
    color: #007bff;
    font-weight: bold;
    text-align:center;
}

.intro p, .mission p {
    font-size: 1.2em;
    line-height: 1.6;
}

.features ul {
    list-style-type: none;
    padding: 0;
}

.features li {
    margin-bottom: 15px;
}

.features ul ul {
    padding-left: 20px;
}

.team {
    text-align: center;
}

.team-members {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.team-member {
    flex: 1 1 200px;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
}

.team-member-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #007bff;
}

.team-member-info h3 {
    margin: 10px 0;
    font-size: 1.5em;
}

.team-member-info p {
    margin: 5px 0;
}

.contact p {
    font-size: 1.2em;
}

.contact a {
    color: #007bff;
    text-decoration: none;
}

.contact a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .team-members {
        flex-direction: column;
    }
}

