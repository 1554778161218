
.user-dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .icon{
    margin-right:6px;
  }
  .username{
    font-size: 1.2rem;
    font-weight: 600;
    padding:auto;
    margin:10px;
    text-decoration:none;
  }
  .dropdown-icon {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 1.8rem;
  }
  
  .dropdown-icon .fa-user {
    margin-right: 8px;
  }
  
  .dropdown-card {
    position: absolute;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 200px;
    z-index: 1000;
  }

  .dropdown-item {
    padding: 12px 16px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  
  .dropdown-item:last-child {
    border-bottom: none;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  