.common-health-concerns {
  padding: 20px;
  margin: 20px 0;
}

.subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.titles {
  font-size: 18px;
  font-weight: bold;
}

.see-all-symptoms {
  font-size: 14px;
  color: #28a745;
  text-decoration: none;
}

.specialties-grid, .problems-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.specialty-card, .problem-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
}

.specialty-card img, .problem-card img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.specialty-card p, .problem-card p {
  margin: 10px 0;
  font-size: 14px;
}

.specialty-card a, .problem-card a {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #28a745;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
}

.specialty-card a:hover, .problem-card a:hover {
  background-color: #218838;
}
