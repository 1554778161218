/* Styles for the register component */
.register-container {
  background-image: url('../../assets/loginui.webp'); 
  background-size: cover;
  background-position: center;
  height: 100vh; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#welcome-heading {
  font-size: 2rem;
  font-weight: bold;
  background-image: repeating-linear-gradient(to right, #553c9a, #ee4b2b 10%, #553c9a 20%);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.register-content {
  background-color: rgba(255, 255, 255, 0.2); /* Transparent white background */
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}
.register-content h2 {
  margin-bottom: 20px;
  color: #333; 
}

.form-group {
  margin-bottom: 20px;
}

.input-field {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.5); 
  border-radius: 20px; 
  background-color: rgba(255, 255, 255, 0.3); 
  box-sizing: border-box; 
  color: #333; 
}

.input-field::placeholder {
  color: black; 
  
}

.btn-register {
  background-color: rgba(255, 255, 255, 0.3); /* Semi-transparent background */
  color: black;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 20px; 
  cursor: pointer;
  transition: background-color 0.3s ease; 
}

.btn-register:hover {
  background-color: black; 
  color:#fff;
}

.login-link {
  margin-top: 20px;
  font-size: 14px;
}

.login-link a {
  color: #007bff;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: node;
}

@media (max-width: 450px){

  #welcome-heading{
    font-size: 22px;
    padding:8px;
  }
  .register-content{
    padding: 8px;
  }
  .register-container {
    background-image: url('../../assets/login-bg.jpeg'); 
    background-repeat: no-repeat;

  }
}