body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

.hero-section {
  background-color: #fff;
  width: 100%;
  margin: 0;
  padding: 40px 20px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.text-content {
  flex: 1;
  min-width: 300px;
  padding: 20px;
}

.text-content h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.text-content p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.doctor-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.doctor-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.online-dot {
  color: green;
  font-size: 1.5em;
}

.button-group {
  margin-bottom:12px;
}

.btn {
  margin-right: 10px;
}

.features {
  font-size: 1em;
  color: #555;
}

.image-content {
  flex: 1;
  min-width: 300px;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.hero-image {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .text-content h1 {
    font-size: 2em;
  }

  .text-content p {
    font-size: 1em;
  }

  .doctor-img {
    width: 40px;
    height: 40px;
  }

  .button-group {
    display: flex;
    flex-direction: column;
  }

  .btn {
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .text-content h1 {
    font-size: 1.5em;
  }

  .text-content p {
    font-size: 0.9em;
  }
  .btn{
    font-size: 0.8em;
  }
  .btn-primary{
    width:50%;
  }

  .doctor-img {
    width: 30px;
    height: 30px;
  }
}
