.main-credit-card{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* height: 100vh; */
      margin: 0;
}
.credit-card{
      width :90%;
      margin: 20px 0;
      padding: 20px;
      background-color: #f9f9f9;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      border: 1px solid #ddd;
      border-radius: 5px;   
}

.main-credit-card .credit-card .heading{
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.main-credit-card .credit-card .heading h2{
    font-size: 1.5rem;
    font-weight: 600;
}
.gif{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display:flex;
    justify-content: center;
    align-items: center;
}
.credit-card-content{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    margin:10px;
}
.credit-card-content div input{
    margin-left:8px;
    padding:5px;
}
.fancy {
    background-color: transparent;
    border: 2px solid #000;
    border-radius: 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    float: right;
    font-weight: 700;
    letter-spacing: 0.05em;
    margin: 8px ;
    outline: none;
    overflow: visible;
    padding:0.5rem 1rem;
    position: relative;
    text-align: center;
    transition: all 0.3s ease-in-out;
    user-select: none;
    font-size: 13px;
   }
   
   .fancy::before {
    content: " ";
    width: 1.5625rem;
    height: 2px;
    background: black;
    top: 50%;
    left: 1.5em;
    position: absolute;
    transform: translateY(-50%);
    transform-origin: center;
    transition: background 0.3s linear, width 0.3s linear;
   }
   
   .fancy .text {
    font-size: 1.125em;
    line-height: 1.33333em;
    padding-left: 2em;
    display: block;
    text-align: left;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
   }
   
   .fancy .top-key {
    height: 2px;
    width: 1.5625rem;
    top: -2px;
    left: 0.625rem;
    position: absolute;
    background: #e8e8e8;
    transition: width 0.5s ease-out, left 0.3s ease-out;
   }
   
   .fancy .bottom-key-1 {
    height: 2px;
    width: 1.5625rem;
    right: 1.875rem;
    bottom: -2px;
    position: absolute;
    background: #e8e8e8;
    transition: width 0.5s ease-out, right 0.3s ease-out;
   }
   
   .fancy .bottom-key-2 {
    height: 2px;
    width: 0.625rem;
    right: 0.625rem;
    bottom: -2px;
    position: absolute;
    background: #e8e8e8;
    transition: width 0.5s ease-out, right 0.3s ease-out;
   }
   
   .fancy:hover {
    color: white;
    background: black;
   }
   
   .fancy:hover::before {
    width: 0.9375rem;
    background: white;
   }
   
   .fancy:hover .text {
    color: white;
    padding-left: 1.5em;
   }
   
   .fancy:hover .top-key {
    left: -2px;
    width: 0px;
   }
   
   .fancy:hover .bottom-key-1,
    .fancy:hover .bottom-key-2 {
    right: 0;
    width: 0;
   }

.recharge-note{
    width :90%;
    padding:20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid #ddd;
    border-radius: 5px;   
    margin: 30px 0;
    background:#f9f9f9;
}

.payment-validation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  .payment-validation-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 600px;
    text-align: center;
  }
  
  .payment-validation-card h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .status-message {
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    color: #fff;
  }
  
  .status-message.success {
    background-color: #4caf50;
  }
  
  .status-message.failure {
    background-color: #f44336;
  }
  
  .details {
    text-align: left;
  }
  
  .details p {
    font-size: 16px;
    margin: 10px 0;
  }
  
  .details strong {
    color: #555;
  }
  