/* Base Styles */
.doctor-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 100%;
  margin: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.doctor-card-header {
  display: flex;
  align-items: center;
}

.doctor-photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 16px;
}

.doctor-name {
  font-size: 1.5rem;
  margin: 0;
}

.doctor-details {
  margin: 16px 0;
}

.doctor-slots {
  margin-top: 16px;
}

.slots-container {
  display: flex;
  flex-wrap: wrap;
}

.slot-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 8px 16px;
  margin: 4px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slot-button:hover {
  background-color: #0056b3;
}

.slot-button.booked {
  background-color: #6c757d;
  cursor: not-allowed;
}

.slot-button:disabled {
  cursor: not-allowed;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .doctor-card {
      flex-direction: column;
      align-items: center;
  }

  .doctor-photo {
      width: 100px;
      height: 100px;
  }
}

@media (max-width: 480px) {
  .doctor-name {
      font-size: 1.25rem;
  }
}
