/* style.css */
.chat-toggle-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #f56505;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .chat-container {
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 300px;
    max-height: 400px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 1000;
  }
  
  .chat-form {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  
  .chat-textarea {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    resize: none;
    min-height: 100px;
  }
  
  .chat-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .chat-button:hover {
    background-color: #0056b3;
  }
  
  .chat-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .chat-response {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
  }
  
  .chat-response-text {
    white-space: pre-wrap;
  }
  