.prescription-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.prescription-header {
    text-align: center;
    margin-bottom: 20px;
}

.prescription-header h1 {
    font-size: 2rem;
    margin: 0;
}

.prescription-header .date {
    color: #555;
}

.prescription-info {
    margin-bottom: 20px;
}

.info-section {
    margin-bottom: 20px;
}

.info-section h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.info-section p {
    margin: 5px 0;
}

.medications-section ul {
    list-style-type: none;
    padding: 0;
}

.medication-item {
    margin-bottom: 10px;
}

.prescription-footer {
    text-align: center;
    margin-top: 20px;
}

.print-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.print-button:hover {
    background-color: #0056b3;
}

.loading, .error, .no-data {
    text-align: center;
    font-size: 1.2rem;
    color: #333;
}
